import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { AuthorSliceType } from "../types/CourseTypes";
import { AuthorDetailsType } from "../types/UserTypes";

const initialState: AuthorSliceType = {
  loadingCourses: true,
  courseData: [],
  draftData: [],
  pendingData: [],
  pastWebinar: {
    loading: true,
    data: [],
  },
  upcomingWebinar: {
    loading: true,
    data: [],
  },
  loadingAuthorData: false,
  authorData: {
    shortDescription: "",
    experienceInYears: "",
    description: "",
    fbUrl: "",
    twitterUrl: "",
    linkedinUrl: "",
    gplusUrl: "",
  },
  isSavingAuthor: false,
  error: "",
};

export const fetchAuthorCourse = createAsyncThunk(
  "getAuthorCourse",
  async () => {
    try {
      const response = await fetchDetails(`promotions/get-author-courses`);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const fetchUpcomingWebinar = createAsyncThunk(
  "getUpcomingWebinar",
  async () => {
    try {
      const response = await fetchDetails(`authors/get-upcoming-webinar`);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const fetchAuthorPastWebinar = createAsyncThunk(
  "getAuthorPastWebinar",
  async () => {
    try {
      const response = await fetchDetails(`authors/get-past-webinar`);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const fetchAuthorProfile = createAsyncThunk(
  "getAuthorProfile",
  async () => {
    try {
      const response = await fetchDetails(`authors/author-profile`);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const saveAuthorData = createAsyncThunk(
  "postAuthorData",
  async (saveData: AuthorDetailsType) => {
    let {
      shortDescription,
      experienceInYears,
      description,
      fbUrl,
      twitterUrl,
      linkedinUrl,
      gplusUrl,
    } = saveData;
    let response = await fetchDetails(`authors/author-profile-update`, 2, {
      shortDescription,
      experienceInYears,
      description,
      fbUrl,
      twitterUrl,
      linkedinUrl,
      gplusUrl,
    });
    return response;
  }
);

export const authorSlice = createSlice({
  name: "school",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthorCourse.pending, (state) => {
        state.loadingCourses = true;
      })
      .addCase(fetchAuthorCourse.fulfilled, (state, action) => {
        let responseCode = action.payload.responseCode;
        if (responseCode === 200 && action.payload) {
          state.courseData = action.payload.response.result.authorCourses;
          state.draftData = action.payload.response.result.authorDrafts;
          state.pendingData = action.payload.response.result.authorPendingCourses;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.loadingCourses = false;
      })
      .addCase(fetchAuthorCourse.rejected, (state, action) => {
        state.loadingCourses = false;
        if (action.error.message) state.error = action.error.message;
      })
      .addCase(fetchUpcomingWebinar.pending, (state) => {
        state.upcomingWebinar.loading = true;
      })
      .addCase(fetchUpcomingWebinar.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          state.upcomingWebinar.data = action.payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.upcomingWebinar.loading = false;
      })
      .addCase(fetchUpcomingWebinar.rejected, (state, action) => {
        state.upcomingWebinar.loading = false;
        if (action.error.message) state.error = action.error.message;
      })
      .addCase(fetchAuthorPastWebinar.pending, (state) => {
        state.pastWebinar.loading = true;
      })
      .addCase(fetchAuthorPastWebinar.fulfilled, (state, action) => {
        let responseCode = action.payload.responseCode;
        if (responseCode === 200 && action.payload) {
          state.pastWebinar.data = action.payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.pastWebinar.loading = false;
      })
      .addCase(fetchAuthorPastWebinar.rejected, (state, action) => {
        state.pastWebinar.loading = false;
        if (action.error.message) state.error = action.error.message;
      })
      .addCase(fetchAuthorProfile.pending, (state) => {
        state.loadingAuthorData = true;
      })
      .addCase(fetchAuthorProfile.fulfilled, (state, action) => {
        let payload = action.payload;
        if (payload) {
          state.authorData =payload.response;
          state.loadingAuthorData = false
        }
      })
      .addCase(fetchAuthorProfile.rejected, (state, action) => {
        if(action.error.message){
          state.error = action.error.message
          state.loadingAuthorData = false
        }
      })
      .addCase(saveAuthorData.pending, (state) => {
        state.isSavingAuthor = true;
      })
      .addCase(saveAuthorData.fulfilled, (state, action) => {
        state.isSavingAuthor = false;
      })
      .addCase(saveAuthorData.rejected, (state, action) => {
        state.isSavingAuthor = false;
        if (action.error.message) state.error = action.error.message;
      });
  },
});
export const {} = authorSlice.actions;
