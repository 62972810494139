import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { MyReview, ReviewSliceType } from "../types/ReviewTypes";

const initialState: ReviewSliceType = {
  loadingReview: true,
  loadingUserReview: true,
  isCliskedLike: false,
  loadingRating : true,
  likeStatus: null,
  avgRating: 0,
  reviews: {
    pagination: {
      isLoading: false,
      total: 0,
      pageNo: 1,
      isNext: false,
    },
    data: [],
  },
  myReview: null,
  ratingData : [],
  error: "",
};

export const fetchMyReview = createAsyncThunk(
  "getMyReview",
  async ({ itemId, itemType }: { itemId: number; itemType: number }) => {
    try {
      let response = await fetchDetails(`webinars/get-my-review`, 2, {
        itemId,
        itemType,
      });
      return response;
    } catch (error) {
      throw error
    }
  }
);

export const fetchAllReview = createAsyncThunk(
  "getAllReview",
  async ({
    pageNo,
    itemId,
    itemType,
  }: {
    pageNo: number;
    itemId: number;
    itemType: number;
  }) => {
    try {
      let response = await fetchDetails(
        `webinars/get-all-reviews/${pageNo}`,
        2,
        { itemId, itemType }
      );

      return response;
    } catch (error) {
      throw error
    }
  }
);
export const likeReview = createAsyncThunk(
  "postLike",
  async ({ id, likeType }: { id: number; likeType: number }) => {
    try {
      let response = await fetchDetails(`webinars/like-review/`, 2, {
        id,
        likeType,
      });
      return response;
    } catch (err) {
      throw err
    }
  }
);
export const fetchRatingByUser = createAsyncThunk(
  "getRatingByUser",
  async ({ itemId, itemType }: { itemId: number; itemType: number }) => {
    try {
      let response = await fetchDetails(`webinars/get-ratings-by-user/`, 2, {
        itemId,
        itemType,
      });
      return response;
    } catch (err) {
      throw err
    }
  }
);
export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    changeReview: (state, action) => {
      if (state.myReview !== null) {
        state.myReview.rating = action.payload[0].elmUserFeedback[0].rating;
        state.myReview.userAnswer =
          action.payload[0].elmUserFeedback[0].userAnswer;
      }
    },
    changeLikeStatus: (state, action) => {
      state.reviews.data[action.payload].totalLike = Number(state.reviews.data[action.payload].totalLike) + 1;
    },
    setPageNo: (state, action) => {
      state.reviews.pagination.pageNo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyReview.pending, (state) => {
        state.loadingUserReview = true;
      })
      .addCase(fetchMyReview.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          if(action.payload.response.myReview.length === 0){
            state.myReview = null
          }else{
            state.myReview = action.payload.response.myReview[0];
          }
          
        }

        state.loadingUserReview = false;
      })
      .addCase(fetchMyReview.rejected, (state, action) => {
        state.loadingUserReview = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllReview.pending, (state) => {
        state.loadingReview =
          state.reviews.pagination.pageNo === 1 ? true : false;
        state.reviews.pagination.isLoading =
          state.reviews.pagination.pageNo > 1 ? true : false;
      })
      .addCase(fetchAllReview.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
          let reviewData : MyReview[] = action.payload.response.reviews;
          state.reviews.pagination.isNext = action.payload.response.isNext;
          state.reviews.pagination.total = action.payload.response.total;
          if(state.reviews.pagination.pageNo === 1){
            state.avgRating = Number(
              action.payload.response.avgRating.averageRating
            );
            state.reviews.data = reviewData
          }else{
            state.reviews.data  = [
              ...state.reviews.data ,
              ...reviewData,
            ];
          }
          state.error = ""
        } else {
          state.error = "Something is wrong"
        }
        state.loadingReview = false;
        state.reviews.pagination.isLoading = false;
      })
      .addCase(fetchAllReview.rejected, (state, action) => {
        state.loadingReview = false;
        state.reviews.pagination.isLoading = false;
        //state.error = action.error.message
        state.error = action.error.message;
      })
      .addCase(likeReview.pending, (state, acttion) => {
        state.isCliskedLike = true;
      })
      .addCase(likeReview.fulfilled, (state, action) => {
        let payload = action.payload;
        if (payload) {
          let responseCode = payload.responseCode;
          if (responseCode === 200) {
            state.likeStatus = payload.response.successCode;
            state.error = ""
          } else {
            state.error = "Something is wrong"
          }
          
        }
        state.isCliskedLike = false;
      })
      .addCase(likeReview.rejected, (state, action) => {
        state.isCliskedLike = false;
        state.error = action.error.message;
      })
      .addCase(fetchRatingByUser.pending, (state, acttion) => {
        state.loadingRating = true;
      })
      .addCase(fetchRatingByUser.fulfilled, (state, action) => {
        let payload = action.payload;
        if (payload) {
          let responseCode = payload.responseCode;
          if (responseCode === 200) {
            state.ratingData = payload.response;
            state.error = ""
          } else {
            state.error= "Something is wrong"
          }
        }
        state.loadingRating = false;
      })
      .addCase(fetchRatingByUser.rejected, (state, action) => {
        state.loadingRating = false;
        state.error = action.error.message;
      })
      ;
  },
});

export const { changeReview, changeLikeStatus, setPageNo } = reviewSlice.actions;
