import React from "react";
import { useAppSelector } from "../hooks/reduxHook";
import { Navigate } from "react-router-dom";

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const userData = useAppSelector((state) => state.userSlice);
  return (
    <>
      {userData.userDetails && (
        <>
          {userData.userDetails.isAuthor === 1 ||
          userData.userDetails.isAdmin === 1 ? (
            <>{children}</>
          ) : (
            <Navigate to="/" />
          )}
        </>
      )}
    </>
  );
};

export default ProtectedRoute;
