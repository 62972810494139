import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import {
  CourseTestSliceType,
  GlossaryDataType,
  GlossaryRootType,
  SubmitResponseType,
} from "../types/CourseTestTypes";

const initialState: CourseTestSliceType = {
  loading: false,
  glossaryData: null,
  courseTestSubmit: false,
  courseTestStart: false,
  completeCertificate : null,
  oneTimeTestReponse : null,
  notCompletedCertificate : null,
  responseType : 0,
  isCertificateTest : false,
  responseMessage : "",
  ExamPanelOpen: false,
  currentQuestionIndex: -1,
  submittingExam: false,
  submitResponse : null,
  submittedExamId : 0,
  error: "",
};

export const fetchExamData = createAsyncThunk(
  "getTestData",
  async ({
    courseId,
    unitId,
    glossaryId,
  }: {
    courseId: number;
    unitId: number;
    glossaryId: number | null;
  }) => {
    if (glossaryId) {
      let response = await fetchDetails(`glossary/unit-test-game-data`, 2, {
        courseId: courseId,
        unitId: unitId,
        glossaryId: glossaryId,
      });
      return response;
    }
  }
);

export const submitExam = createAsyncThunk(
  "saveExamData",
  async ({
    courseId,
    unitId,
    data,
    timeTaken,
  }: {
    courseId: number;
    unitId: number;
    data: GlossaryDataType;
    timeTaken: number;
  }) => {
    let response = await fetchDetails(`glossary/save-test`, 2, {
      courseId,
      unitId,
      data,
      timeTaken,
    });
    return response;
  }
);

export const courseTestSlice = createSlice({
  name: "courseTest",
  initialState,
  reducers: {
    setExamPanel: (state, action) => {
      state.ExamPanelOpen = action.payload;
    },
    setCurrentQuestionIndex: (state, action) => {
      state.currentQuestionIndex = action.payload;
    },
    setCourseTestStart: (state, action) => {
      state.courseTestStart = action.payload;
    },
    selectedAnswer: (state, action) => {
      if (state.glossaryData) {
        let { currentQuestion, answerIndex } = action.payload;
        let allAnswer =
          state.glossaryData.data.glossaryQuestions[currentQuestion];
        allAnswer.glossaryAnswers.map((answer) => {
          answer.isCorrect = false;
          return answer;
        });
        allAnswer.glossaryAnswers[answerIndex].isCorrect = true;
        allAnswer.isAttempt = true;
      }
    },
    resetExam: (state) =>{
      if(state.glossaryData){
        let glossaryQuestion = state.glossaryData.data.glossaryQuestions;
        glossaryQuestion.map((question) => {
          question.isAttempt = false
          question.glossaryAnswers.map((answer) => {
            answer.isCorrect = false
            answer.isCorrectAnswer = false
            answer.isWrongAnswer = false
            return answer
          })
          return question
        })
        state.submitResponse = null
        state.ExamPanelOpen = false
        state.courseTestStart = false
      }
    },

     goToNextSection : (state) => {
        state.submitResponse = null
        state.ExamPanelOpen = false
     }

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExamData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchExamData.fulfilled, (state, action) => {
        let responseData = action.payload;
        if (responseData) {
          //state.glossaryData = action.payload.response
          state.responseType = responseData.response.responseType;
          state.responseMessage = responseData.response.message;
          state.isCertificateTest = responseData.response.isCertificateTest
          if(responseData.response.responseType === 1){
            state.completeCertificate = responseData.response.data
          }else if(responseData.response.responseType === 2){
            state.notCompletedCertificate = responseData.response.data
          } else if(responseData.response.responseType === 3) {
            state.oneTimeTestReponse = responseData.response.data
          } else if(responseData.response.responseType === 4) {
            let glossaryInformation: GlossaryRootType = responseData.response;
            

          glossaryInformation.data.glossaryQuestions.map((question) => {
            question.glossaryAnswers.map((answer) => {
              answer.isCorrect = false;
              answer.isCorrectAnswer = false;
              answer.isWrongAnswer = false
              return answer;
            });
            question.isAttempt = false;
            return question;
          });
          state.glossaryData = glossaryInformation;
          }
            
          state.error = "";
          state.courseTestSubmit = false;
        } else {
          state.error = "Something is wrong";
        }

        state.loading = false;
      })
      .addCase(fetchExamData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(submitExam.pending, (state, action) => {
        state.submittingExam = true;
      })
      .addCase(submitExam.fulfilled, (state, action) => {
        let responseData  : SubmitResponseType= action.payload.response
        state.submitResponse = responseData 
        if(state.glossaryData){
          let submitPayload = state.glossaryData.data.glossaryQuestions
               submitPayload.map((question, index) => {
                      let answerId = responseData.correctAnswer[index].answerId
                      question.glossaryAnswers.map((answer) => {
                        answer.isCorrectAnswer = answer.id === answerId ? true : false
                        return answer
                      })
                      return question
               })
        }
        state.submittingExam = false;
        state.courseTestStart = false;
      })
      .addCase(submitExam.rejected, (state, action) => {
        state.submittingExam = false;
        state.error = action.error.message;
        state.courseTestStart = false;
      });
  },
});
export const {
  setExamPanel,
  setCurrentQuestionIndex,
  setCourseTestStart,
  selectedAnswer,
  resetExam,
  goToNextSection
} = courseTestSlice.actions;
