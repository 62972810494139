import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserSliceType, UserType } from "../types/UserTypes";
import { fetchDetails } from "../utils/fetchRequests";

const initialState: UserSliceType = {
  loading: true,
  userDetails: null,
  userInformations: {
    loading: true,
    data: null,
    errors: [],
    isFetchISDCode: false,
    coutryWithISDCode: [],
  },
  error: "",
  errorCode: 0,
  deleteStatus: false,
  isSubmitProcessing: false,
};

export const fetchCoutryWithISD = createAsyncThunk(
  "data/fetchCoutryWithISD",
  async () => {
    const response = await fetchDetails(`users/get-country-with-code`);
    return response;
  }
);

export const fetchUserData = createAsyncThunk(
  "data/fetchUserData",
  async () => {
    const response = await fetchDetails(`users/user-info`);
    return response;
  }
);

export const fetchUserInformations = createAsyncThunk(
  "data/fetchUserInformations",
  async () => {
    const response = await fetchDetails(`users/user-profile`);
    return response;
  }
);

export const postUserInfo = createAsyncThunk(
  "data/postUserInfo",
  async ({ userDetails }: { userDetails: UserType }) => {
    const {
      firstName,
      lastName,
      address1,
      address2,
      country,
      state,
      city,
      pin,
      occupation,
      gender,
      dateOfBirth,
      experienceInMarket,
    } = userDetails;
    const response = await fetchDetails(`users/update-profile`, 2, {
      firstName,
      lastName,
      address1,
      address2,
      country,
      state,
      city,
      pin,
      occupation,
      gender,
      dateOfBirth,
      experienceInMarket,
    });
    return { response, userDetails };
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loadUserdata: (state) => {
      state.loading = true;
    },
    setUserError: (state) => {
      state.error = "Something is wrong";
    },
    manageDeleteStatus: (state, action) => {
      state.deleteStatus = action.payload;
    },
    changeUserMobile: (state, action) => {
      if (state.userDetails) {
        state.userDetails.mobile = action.payload.mobileNumber;
        state.userDetails.isdCode = action.payload.isdCode;
        state.userDetails.isMobileVerified = 0;
        state.userDetails.mobileVerifiedDate = "";
      }
    },
    changeUserMobileStatus: (state) => {
      if (state.userDetails) {
        state.userDetails.isMobileVerified = 1;
        state.userDetails.mobileVerifiedDate = new Date();
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.response && action.payload.responseCode === 200) {
          state.userDetails = action.payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
          state.errorCode =
            action.payload.responseCode && action.payload.responseCode;
        }
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message) {
          state.error = action.error.message;
          state.errorCode = action.error.code;
        }
      })
      .addCase(fetchUserInformations.pending, (state) => {
        state.userInformations.loading = true;
      })
      .addCase(fetchUserInformations.fulfilled, (state, action) => {
        state.userInformations.loading = false;
        if (action.payload.response && action.payload.responseCode === 200) {
          state.userInformations.data = action.payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
          state.errorCode =
            action.payload.responseCode && action.payload.responseCode;
        }
      })
      .addCase(fetchUserInformations.rejected, (state, action) => {
        state.userInformations.loading = false;
        if (action.error.message) {
          state.error = action.error.message;
          state.errorCode = action.error.code;
        }
      })
      .addCase(postUserInfo.pending, (state) => {
        state.isSubmitProcessing = true;
      })
      .addCase(postUserInfo.fulfilled, (state, action) => {
        if (action.payload.response.responseCode === 200) {
          state.userInformations.data = action.payload.userDetails;
          if (state.userDetails) {
            state.userDetails.firstName = action.payload.userDetails.firstName;
            state.userDetails.lastName = action.payload.userDetails.lastName;
          }
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
        state.isSubmitProcessing = false;
      })
      .addCase(postUserInfo.rejected, (state, action) => {
        if (action.error.message) {
          state.error = action.error.message;
          state.errorCode = action.error.code;
        }
        state.isSubmitProcessing = false;
      })
      .addCase(fetchCoutryWithISD.pending, (state) => {
        // state.isSubmitProcessing = true;
        state.userInformations.isFetchISDCode = true;
      })
      .addCase(fetchCoutryWithISD.fulfilled, (state, action) => {
        if (action.payload.responseCode === 200) {
          state.userInformations.coutryWithISDCode = action.payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
        state.userInformations.isFetchISDCode = false;
      })
      .addCase(fetchCoutryWithISD.rejected, (state, action) => {
        // state.isSubmitProcessing = true;
        if (action.error.message) {
          state.error = action.error.message;
          state.userInformations.isFetchISDCode = false;
        }
      });
  },
});

export const {
  loadUserdata,
  setUserError,
  manageDeleteStatus,
  changeUserMobile,
  changeUserMobileStatus,
} = userSlice.actions;
