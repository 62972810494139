import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { AddCourseType, HostCourseSliceType } from "../types/CourseTypes";

const initialState: HostCourseSliceType = {
  step: 1,
  dropDownData: {
    loading: true,
    data: null,
  },
  error: "",
  isAddCourse : false,
  addCourseResponse : null,
  submitMessage : ""
};

export const fetchDropDown = createAsyncThunk("getDropDown", async () => {
  try {
    const response = await fetchDetails(`courses/get-all-option-data`);
    return response;
  } catch (err) {
    throw err;
  }
});

export const addCourse = createAsyncThunk(
  "courseAdd",
  async (courseSubmitData: AddCourseType) => {
    try {
      const response = await fetchDetails(`courses/add-course-post`,2 ,courseSubmitData);
      return response;
    } catch (err) {
      throw err;
    }
  }
);

export const hostCourseSlice = createSlice({
  name: "hostCourse",
  initialState,
  reducers: {
    setSubmitMessage : (state, action) => {
        state.submitMessage = action.payload
    },
    setStep : (state, action) => {
        state.step = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDropDown.pending, (state) => {
        state.dropDownData.loading = true;
      })
      .addCase(fetchDropDown.fulfilled, (state, action) => {
        let responseCode = action.payload.responseCode;
        if (responseCode === 200 && action.payload) {
          state.dropDownData.data = action.payload.response;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.dropDownData.loading = false;
      })
      .addCase(fetchDropDown.rejected, (state, action) => {
        state.dropDownData.loading = false;
        state.error = action.error.message;
      })
      .addCase(addCourse.pending, (state) => {
        state.isAddCourse = true;
      })
      .addCase(addCourse.fulfilled, (state, action) => {
        let responseCode = action.payload?.responseCode;
        if (responseCode === 200 && action.payload) {
    
          let statusCode: number = action.payload.response.statusCode

           if(statusCode === 1){
            state.submitMessage = "Submit Successfully";
            state.addCourseResponse = action.payload.response.data
            state.step = 2
           } else {
            state.submitMessage = "Something is wrong"
           }
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.isAddCourse= false;
      })
      .addCase(addCourse.rejected, (state, action) => {
        state.isAddCourse= false;
        state.error = action.error.message;
      })
      ;
  },
});
export const {setSubmitMessage, setStep} = hostCourseSlice.actions;
