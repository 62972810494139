import { setError } from "../services/errorSlice";
import { store } from "../store/store";

let tokenPromise: Promise<boolean> | null = null; // Global token refresh promise

export const fetchDetails = async (
  url: string,
  method_type: number = 1,
  data: Object | undefined = {},
  isFormData: boolean = false
): Promise<{ response: any; responseCode: number | undefined }> => {
  let apiUrl = process.env.REACT_APP_LMS_API_URL
    ? process.env.REACT_APP_LMS_API_URL + url
    : "";
  let response: any = null,
    responseCode: number | undefined;
  let options: RequestInit = {
    method: "POST",
    credentials: "include",
  };
  let bodyData: BodyInit | null | undefined;

  try {
    if (method_type === 1) {
      options.method = "GET";
    } else if (method_type === 2 && data) {
      bodyData = isFormData ? (data as FormData) : JSON.stringify(data);
      options.headers = { "Content-Type": "application/json" };
      options.body = bodyData;
    }

    const res = await fetch(apiUrl, options);
    responseCode = res.status;

    if (responseCode === 401) {
      store.dispatch(setError({ errorCode: responseCode }));
      return { response: null, responseCode };
    } else if ([404, 422, 426].includes(responseCode)) {
      return { response: await res.json(), responseCode };
    } else if (responseCode === 200) {
      return { response: await res.json(), responseCode };
    } else if (responseCode === 403) {
      // If token is already being refreshed, wait for it
      if (!tokenPromise) {
        tokenPromise = setToken().finally(() => {
          tokenPromise = null; // Reset after completion
        });
      }

      const tokenRefreshed = await tokenPromise; // Wait for token refresh result

      if (tokenRefreshed) {
        // Retry the API request after successful token refresh
        return await fetchDetails(url, method_type, data, isFormData);
      } else {
        // Token refresh failed, return error response
        return { response: null, responseCode: 401 };
      }
    }
  } catch (error) {
    console.error("Fetch Error:", error);
    throw error;
  }

  return { response, responseCode };
};

export const setToken = async (): Promise<boolean> => {
  let returnCode = await fetch(
    `${process.env.REACT_APP_NEXT_HOME_URL as string}api/auth/session`,
    { credentials: "include" }
  ).then((res) => {
    return res.status;
  });
  if (returnCode === 200) {
    return true;
  } else {
    return false;
  }
};
