import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import {
  CourseBatchType,
  CourseSession,
  LearnSliceType,
} from "../types/LearnTypes";
import { checkVideoExpiredTime } from "../utils/helpers";

const initialState: LearnSliceType = {
  learningData: null,
  loading: false,
  processMark: false,
  refreshUnitId: 0,
  sectionIndex: 0,
  chapterIndex: 0,
  unitIndex: 0,
  batchIndex: -1,
  sessionIndex: -1,
  bottomSectionSelected: 1,
  loadVideoId : false,
  clickUnit : false,
  batchData: {
    loading: false,
    data: [],
    loadSelectedSession: false,
    selectedSession: null,
  },
  error: "",
};

export const fetchLearnData = createAsyncThunk(
  "getLearnData",
  async ({ courseSlug }: { courseSlug: string }) => {
    try {
      const response = await fetchDetails(
        `learn/get-course-section-data-for-initial-state`,
        2,
        { courseSlug }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCourseBatch = createAsyncThunk(
  "getCourseBatch",
  async ({ courseId }: { courseId: number }) => {
    const response = await fetchDetails(`learn/get-user-course-batches`, 2, {
      courseId: courseId,
      isNeedEndDate: false,
    });
    return response;
  }
);

export const fetchBatchSession = createAsyncThunk(
  "getBatchSession",
  async ({ batchId }: { batchId: number }) => {
    const response = await fetchDetails(
      `learn/get-course-batch-session-with-group`,
      2,
      { batchId }
    );
    return response;
  }
);

export const fetchActiveChapterUnit = createAsyncThunk(
  "getActiveChapterUnit",
  async ({ chapterId }: { chapterId: number }) => {
    try {
      const response = await fetchDetails(
        `courses/learn/get-active-chapter-units`,
        2,
        { chapterId }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const getVideoUrlByUnitId = createAsyncThunk(
  "fetchVideoUrlByUnitId",
  async ({
    unitId,
    unitIndex,
    sectionIndex,
    chapterIndex,
  }: {
    unitId: number;
    unitIndex: number;
    sectionIndex: number;
    chapterIndex: number;
  }) => {
    try {
      const response = await fetchDetails(
        `learn/get-video-url-by-unit-id/${unitId}`
      );
      return { response, chapterIndex, sectionIndex, unitIndex};
    } catch (error) {
      throw error;
    }
  }
);

export const markAsRead = createAsyncThunk(
  "postMark",
  async ({
    unitId,
    courseId,
    chapterId,
    sectionId,
    totalUnit,
  }: {
    unitId: number;
    courseId: number;
    chapterId: number;
    sectionId: number;
    totalUnit: number;
  }) => {
    try {
      const response = await fetchDetails(`learn/complete-units`, 2, {
        unitId,
        courseId,
        chapterId,
        sectionId,
        totalUnit,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const learnSlice = createSlice({
  name: "learn",
  initialState,
  reducers: {
    setClickUnit : (state, action) => {
        state.clickUnit = action.payload
    },
    setBottomSection: (state, action) => {
      console.log("function called");
      state.bottomSectionSelected = action.payload;
    },
    setBatchIndex: (state, action) => {
      state.batchIndex = action.payload;
      let sessionOpened = state.batchData.data[state.batchIndex].sessionOpened;
      state.batchData.data[state.batchIndex].sessionOpened = !sessionOpened;
    },
    setSessionIndex: (state, action) => {
      let { bIndex, sIndex } = action.payload;
      state.sessionIndex = sIndex;
      let isOpened = state.batchData.data[bIndex].sessionData[sIndex].isOpen;
      state.batchData.data[bIndex].sessionData[sIndex].isOpen = !isOpened;
    },
    setSelectedSession: (state, action) => {
      state.batchData.selectedSession = action.payload;
    },
    changeUnitMark: (state, action) => {
      if (state.learningData) {
        state.learningData.selectedUnitData.isCompleteUnit = action.payload;
        let sectionIndex = state.sectionIndex;
        let chapterIndex = state.chapterIndex;
        let unitIndex = state.unitIndex;
        state.learningData.courseInitialData.courseData[
          sectionIndex
        ].courseChapters[chapterIndex].courseUnits[unitIndex].isCompleteUnit =
          action.payload;
      }
    },
    openSection: (state, action) => {
      if (state.learningData)
        if (
          state.learningData.courseInitialData.sectionSelected !==
          state.learningData.courseInitialData.courseData[action.payload].id
        ) {
          state.learningData.courseInitialData.sectionSelected =
            state.learningData.courseInitialData.courseData[action.payload].id;
        } else {
          state.learningData.courseInitialData.sectionSelected = 0;
        }
    },
    openChapter: (state, action) => {
      const { sectionIndex, chapterIndex } = action.payload;
      if (state.learningData) {
        if (
          state.learningData.courseInitialData.chapterSelected !==
          state.learningData.courseInitialData.courseData[sectionIndex]
            .courseChapters[chapterIndex].id
        ) {
          state.learningData.courseInitialData.chapterSelected =
            state.learningData.courseInitialData.courseData[
              sectionIndex
            ].courseChapters[chapterIndex].id;
        } else {
          state.learningData.courseInitialData.chapterSelected = 0;
        }
      }
    },
    selectUnit: (state, action) => {
      let { sectionIndex, chapterIndex, unitIndex } = action.payload;

      if (state.learningData) {
        let unitDetails =
          state.learningData.courseInitialData.courseData[sectionIndex]
            .courseChapters[chapterIndex].courseUnits[unitIndex];
        state.learningData.courseInitialData.unitSelected = unitDetails.id;
        state.learningData.courseInitialData.unitData =
          state.learningData.courseInitialData.courseData[
            sectionIndex
          ].courseChapters[chapterIndex].courseUnits[unitIndex].fileContents;
        state.learningData.selectedUnitData =
          state.learningData.courseInitialData.courseData[
            sectionIndex
          ].courseChapters[chapterIndex].courseUnits[unitIndex];
        if (
          unitDetails.fileContents.fileTypeId !== 2 &&
          unitDetails.fileContents.fileTypeId !== 11 &&
          unitDetails.fileContents.fileTypeId !== 7 &&
          unitDetails.fileContents.fileTypeId !== 13
        ) {
          unitDetails.isCompleteUnit = true;
        } else {
          let refreshUnitStatus = checkVideoExpiredTime(
            unitDetails.fileContents.unitUrlNew !== null &&
              unitDetails.fileContents.unitUrlNew.length > 0
              ? unitDetails.fileContents.unitUrlNew
              : unitDetails.fileContents.unitUrl
          );
          if (refreshUnitStatus === true) {
            state.refreshUnitId = unitDetails.id;
          }
        }
        state.sectionIndex = sectionIndex;
        state.chapterIndex = chapterIndex;
        state.unitIndex = unitIndex;
      }
    },

    nextUnit: (state) => {
      if (state.learningData) {
        const currentSection =
          state.learningData.courseInitialData.courseData[state.sectionIndex];
        const currentChapter =
          currentSection.courseChapters[state.chapterIndex];

        if (state.unitIndex < currentChapter.courseUnits.length - 1) {
          state.unitIndex += 1;
        } else if (
          state.chapterIndex <
          currentSection.courseChapters.length - 1
        ) {
          state.chapterIndex += 1;
          state.unitIndex = 0;
        } else if (
          state.sectionIndex <
          state.learningData.courseInitialData.courseData.length - 1
        ) {
          state.sectionIndex += 1;
          state.chapterIndex = 0;
          state.unitIndex = 0;
        }
        state.learningData.courseInitialData.sectionSelected =
          state.learningData.courseInitialData.courseData[
            state.sectionIndex
          ].id;

        state.learningData.courseInitialData.chapterSelected =
          state.learningData.courseInitialData.courseData[
            state.sectionIndex
          ].courseChapters[state.chapterIndex].id;

        state.learningData.courseInitialData.unitSelected =
          state.learningData.courseInitialData.courseData[
            state.sectionIndex
          ].courseChapters[state.chapterIndex].courseUnits[state.unitIndex].id;
        let unitDetails =
          state.learningData.courseInitialData.courseData[state.sectionIndex]
            .courseChapters[state.chapterIndex].courseUnits[state.unitIndex];
        if (
          unitDetails.fileContents.fileTypeId !== 2 &&
          unitDetails.fileContents.fileTypeId !== 11 &&
          unitDetails.fileContents.fileTypeId !== 7 &&
          unitDetails.fileContents.fileTypeId !== 13
        ) {
          state.learningData.courseInitialData.courseData[
            state.sectionIndex
          ].courseChapters[state.chapterIndex].courseUnits[
            state.unitIndex
          ].isCompleteUnit = true;
        }

        state.learningData.selectedUnitData = unitDetails;
      }
    },

    prevUnit: (state) => {
      if (state.learningData) {
        if (state.unitIndex > 0) {
          state.unitIndex -= 1;
        } else if (state.chapterIndex > 0) {
          state.chapterIndex -= 1;
          const prevChapter =
            state.learningData.courseInitialData.courseData[state.sectionIndex]
              .courseChapters[state.chapterIndex];
          state.unitIndex = prevChapter.courseUnits.length - 1;
        } else if (state.sectionIndex > 0) {
          state.sectionIndex -= 1;
          const prevCourse =
            state.learningData.courseInitialData.courseData[state.sectionIndex];
          state.chapterIndex = prevCourse.courseChapters.length - 1;
          state.unitIndex =
            prevCourse.courseChapters[state.chapterIndex].courseUnits.length -
            1;
        }
        state.learningData.courseInitialData.sectionSelected =
          state.learningData.courseInitialData.courseData[
            state.sectionIndex
          ].id;

        state.learningData.courseInitialData.chapterSelected =
          state.learningData.courseInitialData.courseData[
            state.sectionIndex
          ].courseChapters[state.chapterIndex].id;

        state.learningData.courseInitialData.unitSelected =
          state.learningData.courseInitialData.courseData[
            state.sectionIndex
          ].courseChapters[state.chapterIndex].courseUnits[state.unitIndex].id;
        let unitDetails =
          state.learningData.courseInitialData.courseData[state.sectionIndex]
            .courseChapters[state.chapterIndex].courseUnits[state.unitIndex];
        if (
          unitDetails.fileContents.fileTypeId !== 2 &&
          unitDetails.fileContents.fileTypeId !== 11
        ) {
          state.learningData.courseInitialData.courseData[
            state.sectionIndex
          ].courseChapters[state.chapterIndex].courseUnits[
            state.unitIndex
          ].isCompleteUnit = true;
        }
        state.learningData.selectedUnitData = unitDetails;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLearnData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLearnData.fulfilled, (state, action) => {
        let responseCode = action.payload.responseCode;
        if (responseCode === 200) {
          state.learningData = action.payload.response.data;
          if (state.learningData) {
            let sectionSelected =
              state.learningData.courseInitialData.sectionSelected;
            let chapterSelected =
              state.learningData.courseInitialData.chapterSelected;
            let unitSelected =
              state.learningData.courseInitialData.unitSelected;
            if (sectionSelected)
              state.sectionIndex =
                state.learningData.courseInitialData.courseData.findIndex(
                  (section) => section.id === sectionSelected
                );
            else state.sectionIndex = 0;
            if (chapterSelected && sectionSelected)
              state.chapterIndex =
                state.learningData.courseInitialData.courseData[
                  state.sectionIndex
                ].courseChapters.findIndex(
                  (chapter) => chapter.id === chapterSelected
                );
            else state.sectionIndex = 0;
            if (chapterSelected && sectionSelected && unitSelected)
              state.unitIndex = state.learningData.courseInitialData.courseData[
                state.sectionIndex
              ].courseChapters[state.chapterIndex].courseUnits.findIndex(
                (unit) => unit.id === unitSelected
              );
            else state.unitIndex = 0;
            state.learningData.selectedUnitData =
              state.learningData.courseInitialData.courseData[
                state.sectionIndex
              ].courseChapters[state.chapterIndex].courseUnits[state.unitIndex];
          }
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }

        state.loading = false;
      })
      .addCase(fetchLearnData.rejected, (state, action) => {
        if (action.error.message) state.error = action.error.message;
        state.loading = false;
      })
      .addCase(markAsRead.pending, (state, action) => {
        state.processMark = true;
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        if (action.payload && action.payload.responseCode === 200) {
          state.error = "";
          if (state.learningData) {
            if (
              state.learningData.courseCompletionPercentageInfo !==
              action.payload.response.totalCompletePercentage
            ) {
              let selectedSectionData =
                state.learningData.courseInitialData.courseData[
                  state.sectionIndex
                ];
              if (
                action.payload.response.totalCompletePercentage <
                state.learningData.courseCompletionPercentageInfo
              ) {
                selectedSectionData.sectionCompletionPercentage.totalUnitLearn -= 1;
              } else if (
                action.payload.response.totalCompletePercentage >
                state.learningData.courseCompletionPercentageInfo
              ) {
                selectedSectionData.sectionCompletionPercentage.totalUnitLearn += 1;
              }

              selectedSectionData.sectionCompletionPercentage.totalPercentage =
                Math.round(
                  (selectedSectionData.sectionCompletionPercentage
                    .totalUnitLearn /
                    selectedSectionData.sectionCompletionPercentage.totalUnit) *
                    100
                );
              state.learningData.courseCompletionPercentageInfo =
                action.payload.response.totalCompletePercentage;
            }
          }
        } else {
          state.error = "Something is wrong";
        }
        state.processMark = false;
      })

      .addCase(markAsRead.rejected, (state, action) => {
        if (action.error.message) {
          state.error = action.error.message;
          state.processMark = false;
        }
      })
      .addCase(fetchCourseBatch.pending, (state) => {
        state.batchData.loading = true;
      })
      .addCase(fetchCourseBatch.fulfilled, (state, action) => {
        if (action.payload.responseCode === 200) {
          let batchData: CourseBatchType[] = action.payload.response.data;
          console.log(
            "batchData from learn slice",
            action.payload.response.data
          );
          batchData &&
            batchData.map((batch) => {
              batch.sessionOpened = false;
              batch.sessionData = [];
              return batch;
            });
          state.batchData.data = batchData;
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
        state.batchData.loading = false;
      })
      .addCase(fetchCourseBatch.rejected, (state, action) => {
        if (action.error.message) {
          state.error = action.error.message;
          state.batchData.loading = false;
        }
      })
      .addCase(fetchBatchSession.pending, (state) => {
        //state.batchData.data.loading = true
      })
      .addCase(fetchBatchSession.fulfilled, (state, action) => {
        if (action.payload.responseCode === 200) {
          let sessionData: CourseSession[] = action.payload.response.data;
          sessionData &&
            sessionData.map((session) => {
              session.isOpen = false;
              return session;
            });
          state.batchData.data[state.batchIndex].sessionData = sessionData;
          // state.batchData.data[state.batchIndex].sessionOpened = !state.batchData.data[state.batchIndex].sessionOpened
          state.error = "";
        } else {
          state.error = "Something is wrong";
        }
      })
      .addCase(fetchBatchSession.rejected, (state, action) => {
        if (action.error.message) {
          state.error = action.error.message;
        }
      })
      .addCase(getVideoUrlByUnitId.pending, (state) => {
        state.loadVideoId = true
      })
      .addCase(getVideoUrlByUnitId.fulfilled, (state, action) => {
        state.loadVideoId = false
      })
      .addCase(getVideoUrlByUnitId.rejected, (state, action) => {
        state.loadVideoId = false
      })
      ;
  },
});
export const {
  openSection,
  openChapter,
  selectUnit,
  nextUnit,
  prevUnit,
  changeUnitMark,
  setBottomSection,
  setBatchIndex,
  setSessionIndex,
  setSelectedSession,
  setClickUnit
} = learnSlice.actions;
