import { configureStore } from '@reduxjs/toolkit'
import { webinarSlice } from '../services/webinarSlice'
import { courseSlice } from '../services/courseSlice'
import { errorSlice } from '../services/errorSlice'
import { userSlice } from '../services/userSlice'
import { reviewSlice } from '../services/reviewSlice'
import { certificateSlice } from '../services/certtificateSlice'
import { schoolSlice } from '../services/schoolSlice'
import { offerSlice } from '../services/offerSlice'
import { orderSlice } from '../services/orderSlice'
import { promotionSlice } from '../services/promotionSlice'
import { discussionSlice } from '../services/discussionSlice'
import { commentSlice } from '../services/commentSlice'
import { authorSlice } from '../services/authorSlice'
import { affiliateSlice } from '../services/affiliateSlice'
import { hostCourseSlice } from '../services/hostCourseSlice'
import { learnSlice } from '../services/learnSlice'
import { courseTestSlice } from '../services/courseTestSlice'

export const store = configureStore({
  reducer:{
    courseSlice : courseSlice.reducer,
    webinarSlice: webinarSlice.reducer,
    errorSlice : errorSlice.reducer,
    userSlice : userSlice.reducer,
    reviewSlice : reviewSlice.reducer,
    certificateSlice : certificateSlice.reducer,
    schoolSlice : schoolSlice.reducer,
    offerSlice : offerSlice.reducer,
    orderSlice : orderSlice.reducer,
    promotionSlice : promotionSlice.reducer,
    discussionSlice : discussionSlice.reducer,
    commentSlice : commentSlice.reducer,
    authorSlice : authorSlice.reducer,
    affiliateSlice : affiliateSlice.reducer,
    hostCourseSlice : hostCourseSlice.reducer,
    learnSlice : learnSlice.reducer,
    courseTestSlice : courseTestSlice.reducer
  },
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
