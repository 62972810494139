import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import { SchoolSliceType } from "../types/SchoolType";

const initialState: SchoolSliceType = {
    schoolData : {
        loading : true,
        data : []
    },
    error : ""
}

export const fetchElmSchool = createAsyncThunk("getElmSchool", async () => {
    try {
      const response = await fetchDetails(`schools/get-all-current-read-modules`);
      return response;
    } catch (err) {
      throw err
    }
  });

  export const schoolSlice = createSlice({
    name: "school",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchElmSchool.pending, (state) => {
          state.schoolData.loading = true;
        })
        .addCase(fetchElmSchool.fulfilled, (state, action) => {
          let responseCode = action.payload?.responseCode;
          if (responseCode === 200 && action.payload) {
             state.schoolData.data = action.payload.response
             state.error = ""
            
          } else {
            state.error = "Something is wrong"
          }
  
          state.schoolData.loading = false;
        })
        .addCase(fetchElmSchool.rejected, (state, action) => {
            state.schoolData.loading = false;
            state.error = action.error.message;
        })
        ;
    },
  });
