import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import {
  CourseType,
  CourseSliceType,
  CoursePlanType,
} from "../types/CourseTypes";
import { FilterDataType } from "../types/WebinarType";

const initialState: CourseSliceType = {
  loadingIncompletedCourse: true,
  loadingCompletedCourse: true,
  completedCourses: {
    pagination: {
      isLoading: false,
      total: 0,
      pageNo: 1,
      isNext: false,
    },
    courses: [],
  },
  onGoingCourses: {
    pagination: {
      isLoading: false,
      total: 0,
      pageNo: 1,
      isNext: false,
    },
    courses: [],
  },
  coursePlanData: {
    pagination: {
      isLoading: false,
      total: 0,
      pageNo: 1,
      isNext: false,
    },
    courseData: [],
    loadCourseData: false,
    isSelectedFilter: false,
    filerList: [],
    loadingFilterList: true,
    bookedIndex : -1,
    isClickedBookNow : false,
    bookNowMsg : "",
    selectedArray : []
  },
  error: "",
};
export const fetchIncompletedCourseData = createAsyncThunk(
  "getIncompletedCourse",
  async (pageNo: number) => {
    try {
      let response = await fetchDetails(
        `courses/get-user-purchased-courses-ongoing/${pageNo}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchCompletedCourseData = createAsyncThunk(
  "getCompletedCourse",
  async (pageNo: number) => {
    try {
      let response = await fetchDetails(
        `courses/get-user-purchased-courses-completed/${pageNo}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchCourseFilter = createAsyncThunk(
  `getCourseFilter`,
  async () => {
    try {
      let response = await fetchDetails(`subscriptions/get-course-filters`);
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchCourseList = createAsyncThunk(
  "getCourseList",
  async ({
    postData,
    pageNo,
  }: {
    postData: FilterDataType[];
    pageNo: number;
  }) => {
    try {
      let response = await fetchDetails(
        `subscriptions/course-list-with-filter/${pageNo}`,
        2,
        { selectedData: postData }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);
export const bookToCourseList = createAsyncThunk(
  "postCourseList",
  async ({slug, productType}: {
    slug : string
    productType: number;
  }) => {
    try {
      let response = await fetchDetails(`payments/add-item-to-plan`, 2, {
        slug,
        productType,
      });
      return response;
    } catch (error) {
      throw error
    }
  }
);
export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    loadCourseData: (state) => {
      state.loadingIncompletedCourse = true;
      state.loadingCompletedCourse = true;
    },
    loadPagination: (state, action) => {
      if (action.payload.ongoing === true) {
        state.onGoingCourses.pagination.isLoading = true;
      } else {
        state.completedCourses.pagination.isLoading = true;
      }
    },
    setBookedCourseIndex : (state, action) => {
     state.coursePlanData.bookedIndex = action.payload
    },
    setCourseError: (state) => {
      state.error = "something wrong";
    },
    setOnGoingPageNo: (state, action) => {
      state.onGoingCourses.pagination.pageNo = action.payload;
    },
    setCompletedPageNo: (state, action) => {
      state.completedCourses.pagination.pageNo = action.payload;
    },
    setCoursePlanPageNo: (state, action) => {
      state.coursePlanData.pagination.pageNo = action.payload;
    },
    removeCheckList: (state, action) => {
      // let paramsData = action.payload;
      // let selectedIndex = state.filerList.findIndex(
      //   (filter) => filter.openType === paramsData.menuType
      // );
      // let removePIndex = state.filerList[selectedIndex].types.findIndex(
      //   (checked) => checked.slug === action.payload.slug
      // );
      // let removeSelectIndex = state.selectedFilterList.findIndex(
      //   (r) => r.slug === action.payload.slug
      // );

      state.coursePlanData.filerList[action.payload.pindex].types[
        action.payload.cindex
      ].isChecked = false;
      state.coursePlanData.filerList[action.payload.pindex].types[
        action.payload.cindex
      ].selected = 0;
      let selectedStatus = state.coursePlanData.filerList.some(
        (category) =>
          category.openType !== 9 &&
          category.types.some((item) => item.selected === 1)
      );
      state.coursePlanData.isSelectedFilter = selectedStatus;

      // state.selectedFilterList.splice(removeSelectIndex, 1);
    },
    checkedToogle: (state, action) => {
      state.coursePlanData.filerList[action.payload.indp].types[
        action.payload.indc
      ].isChecked = action.payload.ckeckedItem;
      state.coursePlanData.filerList[action.payload.indp].types[
        action.payload.indc
      ].selected = action.payload.ckeckedItem === true ? 1 : 0;
      //state.selectedFilterList = state.filerList
      // if (action.payload.ckeckedItem === true) {
      //   state.selectedFilterList.push(
      //     state.filerList[action.payload.indp]
      //   );
      // } else {
      //   let removeIndex = state.selectedFilterList.findIndex(
      //     (x) =>
      //       x.slug ===
      //       state.filerList[action.payload.indp].types[action.payload.indc].slug
      //   );
      //   if (removeIndex > -1) {
      //     state.selectedFilterList.splice(removeIndex, 1);
      //   }
      // }
    },
    filterToggle: (state, action) => {
      if (action.payload.showFilter === true) {
        state.coursePlanData.filerList.map((data) => {
          data.isOpened = false;
        });
      }
      state.coursePlanData.filerList[action.payload.ind].isOpened =
        action.payload.showFilter;
    },

    clearAllFilter: (state) => {
      state.coursePlanData.filerList.map((filter) => {
        filter.isOpened = false;
        filter.types.map((checkList) => {
          checkList.isChecked = false;
          checkList.selected = filter.openType === 9 ? 1 : 0;
          checkList.menuType = filter.openType;
          checkList.slug = checkList.slug
            ? checkList.slug
            : checkList.name.toLowerCase();
        });
      });
      state.coursePlanData.isSelectedFilter = false;
      state.coursePlanData.selectedArray = []
    },
    setSelectedArray : (state, action) => {
      let {isChecked, childIndex} = action.payload
      if(isChecked){
        state.coursePlanData.selectedArray.push(childIndex)
      }else{
        state.coursePlanData.selectedArray = state.coursePlanData.selectedArray.filter(index => index !== childIndex);
      }
    },
    clearPlanData: (state) => {
      state.coursePlanData.courseData = [];
    },
    addToCourseData : (state, action) => {
          let courseListData : CourseType = action.payload;
          state.onGoingCourses.courses.unshift(courseListData)
        }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncompletedCourseData.pending, (state) => {
        state.loadingIncompletedCourse =
          state.onGoingCourses.pagination.pageNo === 1 ? true : false;
        state.onGoingCourses.pagination.isLoading =
          state.onGoingCourses.pagination.pageNo > 1 ? true : false;
      })

      .addCase(fetchIncompletedCourseData.fulfilled, (state, action) => {
        if (state.error === "") {
          let responseCode = action.payload && action.payload.responseCode;
          if (responseCode === 200) {
            let courseData: CourseType[] =
              action.payload.response.invoiceCourses;
            state.onGoingCourses.pagination.isNext =
              action.payload.response.isNext;
            state.onGoingCourses.pagination.total =
              action.payload.response.total;

            courseData &&
              courseData.map((course) => {
                const datediff = Math.floor(
                  (new Date(course.expiryDate).getTime() -
                    new Date().getTime()) /
                    (1000 * 60 * 60 * 24)
                );

                course.noOfDays = datediff + 1;

                return course;
              });

            if (state.onGoingCourses.pagination.pageNo === 1) {
              state.onGoingCourses.courses = courseData;
            } else {
              state.onGoingCourses.courses = [
                ...state.onGoingCourses.courses,
                ...courseData,
              ];
            }

            state.error = "";
          } else {
            state.error = "Something is wrong";
          }
        }

        state.loadingIncompletedCourse = false;
        state.onGoingCourses.pagination.isLoading = false;
      })
      .addCase(fetchIncompletedCourseData.rejected, (state, action) => {
        state.loadingIncompletedCourse = false;
        state.onGoingCourses.pagination.isLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchCompletedCourseData.pending, (state) => {
        state.loadingCompletedCourse =
          state.completedCourses.pagination.pageNo === 1 ? true : false;
        state.completedCourses.pagination.isLoading =
          state.completedCourses.pagination.pageNo > 1 ? true : false;
      })
      .addCase(fetchCompletedCourseData.fulfilled, (state, action) => {
        if (state.error === "") {
          let responseCode = action.payload && action.payload.responseCode;
          if (responseCode === 200) {
            let courseData: CourseType[] =
              action.payload.response.invoiceCourses;
            state.completedCourses.pagination.isNext =
              action.payload.response.isNext;
            state.completedCourses.pagination.total =
              action.payload.response.total;

            courseData &&
              courseData.map((course) => {
                const datediff = Math.floor(
                  (new Date(course.expiryDate).getTime() -
                    new Date().getTime()) /
                    (1000 * 60 * 60 * 24)
                );
                course.noOfDays = datediff + 1;
                return course;
              });

            if (state.completedCourses.pagination.pageNo === 1) {
              state.completedCourses.courses = courseData;
            } else {
              state.completedCourses.courses = [
                ...state.completedCourses.courses,
                ...courseData,
              ];
            }

            state.error = "";
          } else {
            state.error = "Something is wrong";
          }
        }
        state.loadingCompletedCourse = false;
        state.completedCourses.pagination.isLoading = false;
      })
      .addCase(fetchCompletedCourseData.rejected, (state, action) => {
        state.loadingCompletedCourse = false;
        state.error = action.error.message;
      })
      .addCase(fetchCourseFilter.pending, (state) => {
        state.coursePlanData.loadingFilterList = true;
      })
      .addCase(fetchCourseFilter.fulfilled, (state, action) => {
        if (action.payload) {
          let responseCode = action.payload.responseCode;
          if (responseCode === 200) {
            let filterData: FilterDataType[] =
              action.payload.response.data.filter_data;
            filterData &&
              filterData.map((filter) => {
                filter.isOpened = false;
                filter.isDisplay = filter.openType === 9 ? false : true;
                filter.types.map((checkList) => {
                  checkList.isChecked = false;
                  checkList.selected = filter.openType === 9 ? 1 : 0;
                  checkList.menuType = filter.openType;
                  checkList.slug = checkList.slug
                    ? checkList.slug
                    : checkList.name.toLowerCase();
                });
              });

            state.coursePlanData.filerList = filterData;
            state.error = "";
          } else {
            state.error = "Something is wrong";
          }
        }
        state.coursePlanData.loadingFilterList = false;
      })
      .addCase(fetchCourseFilter.rejected, (state, action) => {
        state.coursePlanData.loadingFilterList = false;
        state.error = action.error.message;
      })
      .addCase(fetchCourseList.pending, (state) => {
        state.coursePlanData.loadCourseData =
          state.coursePlanData.pagination.pageNo === 1 ? true : false;
        state.coursePlanData.pagination.isLoading =
          state.coursePlanData.pagination.pageNo > 1 ? true : false;
      })
      .addCase(fetchCourseList.fulfilled, (state, action) => {
        if (action.payload) {
          let responseCode = action.payload.responseCode;
          if (responseCode === 200) {
            let courseList: CoursePlanType[] =
              action.payload.response.data.courseList;
            state.coursePlanData.pagination.isNext =
              action.payload.response.data.isNext;
            state.coursePlanData.pagination.total =
              action.payload.response.total;
            state.coursePlanData.courseData = [
              ...state.coursePlanData.courseData,
              ...courseList,
            ];
            state.error = "";
          } else {
            state.error = "Something wrong";
          }
        }
        state.coursePlanData.loadCourseData = false;
        state.coursePlanData.pagination.isLoading = false;
      })
      .addCase(fetchCourseList.rejected, (state, action) => {
        state.coursePlanData.loadCourseData = false;
        state.error = action.error.message;
      })
         .addCase(bookToCourseList.pending, (state) => {
              state.coursePlanData.isClickedBookNow = true;
            })
      
            .addCase(bookToCourseList.fulfilled, (state, action) => {
              if (action.payload) {
                let responseCode = action.payload.responseCode;
                if (responseCode === 200) {
                  state.coursePlanData.bookNowMsg = action.payload.response.message;
                  if (action.payload.response.success === true) {
                    state.coursePlanData.courseData[state.coursePlanData.bookedIndex].isAdded =
                      1;
                  } else {
                    state.coursePlanData.courseData[state.coursePlanData.bookedIndex].isAdded =
                      0;
                  }
                  state.error = "";
                } else {
                  state.error = "Something wrong";
                }
              }
              state.coursePlanData.isClickedBookNow = false;
            })
      
            .addCase(bookToCourseList.rejected, (state, action) => {
              state.coursePlanData.isClickedBookNow = false;
              state.error = action.error.message;
            })
      ;
  },
});
export const {
  loadCourseData,
  loadPagination,
  setCourseError,
  setOnGoingPageNo,
  setCompletedPageNo,
  setCoursePlanPageNo,
  removeCheckList,
  checkedToogle,
  filterToggle,
  clearAllFilter,
  clearPlanData,
  addToCourseData,
  setBookedCourseIndex,
  setSelectedArray
} = courseSlice.actions;
