export const calculateTimimg = (d: number) => {
  let months = 0,
    years = 0,
    days = 0,
    weeks = 0;
  while (d) {
    if (d >= 365) {
      years++;
      d -= 365;
    } else if (d >= 30) {
      months++;
      d -= 30;
    } else if (d >= 7) {
      weeks++;
      d -= 7;
    } else {
      days++;
      d--;
    }
  }
  let str = "";
  if (years > 0 && years === 1) {
    str = str + years + " year";
  } else if (years > 1) {
    str = str + years + " years";
  }
  if (months > 0 && months === 1) {
    str = str + months + "month";
  } else if (months > 1) {
    str = str + months + "months";
  }
  return str;
};

export const formatDate = (
  dateString: string | Date,
  forDate: boolean = true
): string => {
  const dt = new Date(dateString);
  const day = dt.getUTCDate();
  if (forDate) {
    const month = dt.toLocaleString("default", { month: "short" });
    const year = dt.getFullYear();
    const suffix = (n: number) => {
      const s = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    };
    return `${suffix(day)} ${month} ${year}`;
  } else {
    const hours = dt.getUTCHours().toString().padStart(2, "0");
    const minutes = dt.getUTCMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  }
};

export const addHours = (date: Date, hours: number): Date => {
  const newDate = new Date(date);
  newDate.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return newDate;
};

export const changeFloatNumber = (floatNum: number) => {
  let roundedNum = Math.round(floatNum * 10) / 10;
  return roundedNum;
};

export const copyToClipboard = async (text: string): Promise<{
  msg: string;
  showMsg: boolean;
}> => {
  let msg: string = "";
  let showMsg: boolean = false;

  console.log("Clipboard API available:", !!navigator.clipboard);


  if (navigator.clipboard && navigator.clipboard.writeText) {
    try {
      console.log("Attempting to write to clipboard:", text);
      await navigator.clipboard.writeText(text);
      msg = "Text copied to clipboard!";
    } catch (err) {
      console.error("Failed to copy text:", err);
      msg = "Failed to copy text.";
    }
  } else {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed"; // Prevent scrolling to the bottom
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    msg = "Text copied to clipboard!";
  }

  showMsg = true;
  return { msg, showMsg };
};


export const createSlug = (text: string) => {
  return text
    .toLowerCase()
    .trim()
    .replace(/[\s\W-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const  getMonthName = (monthNumber : number) =>{
  const date = new Date();
  date.setMonth(monthNumber - 1); // Setting month to the given month number
  return new Intl.DateTimeFormat("en-US", { month: "long" }).format(date);
}
export const courseInitialState = {
  courseName: "",
  courseSlug: "",
  nameInEnglish: "",
  pgoto: "",
  courseDeliverymodeId: -1,
  courseLanguageId: -1,
  courseSubjectId: -1,
  courseLevelId: -1,
  savingState: -1,
  status: 0,
  introduction: "",
  objective: "",
  benefits: "",
  contentTopicSummary: "",
  targetAudience: "",
  promoVideo: "",
  duration: -1,
  tuition: -1,
  actualPrice: 0,
  offerPrice: 0,
};

export const  getFileTypeByExtension = (url: string): 'mp4' | 'm3u8' | 'unknown'  =>{
  const extension = url.split('?')[0].split('.').pop(); // Extract extension before query params
  if (extension === 'mp4') {
    return 'mp4';
  } else if (extension === 'm3u8') {
    return 'm3u8';
  }
  return 'unknown';
}


function getTimestampFromUrl(url: string): number | null {
  const match = url.match(/Expires=(\d+)/);
  return match ? parseInt(match[1], 10) : null;
}

 export function checkVideoExpiredTime(url: string) {
  const urlTimestamp = getTimestampFromUrl(url);
  if (!urlTimestamp) {
      console.error("Timestamp not found in the URL");
      return;
  }
  
  const currentTimestamp = Math.floor(Date.now() / 1000);
  
  if (currentTimestamp > urlTimestamp) {
     return true
  } else {
      return false
  }
}

export const isDifferenceLessThan5Minutes = (timestamp1: number | string | Date, timestamp2: number | string | Date): boolean => {
  const time1 = new Date(timestamp1).getTime();
  const time2 = new Date(timestamp2).getTime();
  return Math.abs(time1 - time2) < 1 * 60 * 1000; // 5 minutes in milliseconds
};



