/* eslint-disable array-callback-return */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDetails } from "../utils/fetchRequests";
import type {
  CheckListType,
  FilterDataType,
  SubscriptionType,
  WebinarListType,
  WebinarSliceType,
  WebinarType,
} from "../types/WebinarType";

const initialState: WebinarSliceType = {
  loadingSubscription: true,
  loadingPastWebinar: true,
  loadingUpcomingWebinar: true,
  loadingFilterList: true,
  loadingWebinarList: true,
  isClickedBookNow: false,
  loadingWebinarDetails : true,
  error: "",
  subscriptionData: null,
  webinarUpcomingData: [],
  webinarPastData: {
    pagination: {
      isLoading: false,
      total: 0,
      pageNo: 1,
      isNext: false,
    },
    webinars: [],
  },
  filerList: [],
  webinarPlanData: {
    pagination: {
      isLoading: false,
      total: 0,
      pageNo: 1,
      isNext: false,
    },
    upComingWebinarList: [],
    recordedWebinarList : [],
    isSelectedFilter : false,
    selectedArray : []
  },
  bookNowMsg: "",
  bookedIndex: 0,
  webinarDetails : null
};
export const fetchPastWebinar = createAsyncThunk(
  "getPastWebinar",
  async ({pageNo  ,searchText} : {pageNo : number, searchText : string}) => {
    try {
      let response = await fetchDetails(
        `webinars/get-user-webinars/${pageNo}`,
        2,
        {searchText}
      );
      return response;
    } catch (error) {
      throw error
    }
  }
);
export const fetchUpcomingData = createAsyncThunk(
  "getUpcomingWebinar",
  async (pageNo: number) => {
    try {
      let response = await fetchDetails(
        `webinars/get-user-upcoming-webinar/${pageNo}`,
        2,
        {}
      );
      return response;
    } catch (error) {
      throw error
    }
  }
);

export const fetchSubscriptionPlan = createAsyncThunk(
  "getSubscriptionPlan",
  async () => {
    try {
      let response = await fetchDetails(
        `subscriptions/get-current-subscription-plan/`
      );
      return response;
    } catch (error) {
      throw error
    }
  }
);
export const fetchFilterList = createAsyncThunk("getfilterlist", async () => {
  try {
    let response = await fetchDetails(`subscriptions/get-webinar-filters/`);
    return response;
  } catch (error) {
    throw error
  }
});
export const fetchWebinarList = createAsyncThunk(
  "getWebinarList",
  async ({
    postData,
    pageNo,
  }: {
    postData: FilterDataType[];
    pageNo: number;
  }) => {
    try {
      let response = await fetchDetails(
        `subscriptions/webinar-list-with-filter/${pageNo}`,
        2,
        { selectedData : postData }
      );
      return response;
    } catch (error) {
      throw error
    }
  }
);
export const bookToWebinarList = createAsyncThunk(
  "postWebinarList",
  async ({slug, productType}: {
    slug : string
    productType: number;
  }) => {
    try {
      let response = await fetchDetails(`payments/add-item-to-plan`, 2, {
        slug,
        productType,
      });
      return response;
    } catch (error) {
      throw error
    }
  }
);

export const fetchWebinarDetails = createAsyncThunk('getWebinarDetails',async({slug} : {slug : string})=> {
  try{

    let response = await fetchDetails(`webinars/get-webinar-details/${slug}`)
    return response;
  } catch(error){
    throw error
  }

})
export const webinarSlice = createSlice({
  name: "webinar",
  initialState,
  reducers: {
    setWebinarError: (state) => {
      state.error = "Something wrong";
    },
    setSelectedArray : (state, action) => {
      let {isChecked, childIndex} = action.payload
      if(isChecked){
        state.webinarPlanData.selectedArray.push(childIndex)
      }else{
        state.webinarPlanData.selectedArray = state.webinarPlanData.selectedArray.filter(index => index !== childIndex);
      }
    },
    loadPagination: (state) => {
      state.webinarPastData.pagination.isLoading = true;
    },
    loadPaginationWebinarList: (state) => {
      state.webinarPlanData.pagination.isLoading = true;
    },
    setWebinarListPageNo: (state, action) => {
      state.webinarPlanData.pagination.pageNo = action.payload;
    },
    setPageNo: (state, action) => {
      state.webinarPastData.pagination.pageNo = action.payload;
    },
    filterToggle: (state, action) => {
      if (action.payload.showFilter === true) {
        state.filerList.map((data) => {
          data.isOpened = false;
        });
      }
      state.filerList[action.payload.ind].isOpened = action.payload.showFilter;
    },
    checkedToogle: (state, action) => {
      state.filerList[action.payload.indp].types[action.payload.indc].isChecked =
        action.payload.ckeckedItem;
        state.filerList[action.payload.indp].types[action.payload.indc].selected = action.payload.ckeckedItem  === true ? 1 : 0
        //state.selectedFilterList = state.filerList
        // if (action.payload.ckeckedItem === true) {
      //   state.selectedFilterList.push(
      //     state.filerList[action.payload.indp]
      //   );
      // } else {
      //   let removeIndex = state.selectedFilterList.findIndex(
      //     (x) =>
      //       x.slug ===
      //       state.filerList[action.payload.indp].types[action.payload.indc].slug
      //   );
      //   if (removeIndex > -1) {
      //     state.selectedFilterList.splice(removeIndex, 1);
      //   }
      // }
    },
    removeCheckList: (state, action) => {
      // let paramsData = action.payload;
      // let selectedIndex = state.filerList.findIndex(
      //   (filter) => filter.openType === paramsData.menuType
      // );
      // let removePIndex = state.filerList[selectedIndex].types.findIndex(
      //   (checked) => checked.slug === action.payload.slug
      // );
      // let removeSelectIndex = state.selectedFilterList.findIndex(
      //   (r) => r.slug === action.payload.slug
      // );
    
      state.filerList[action.payload.pindex].types[action.payload.cindex].isChecked = false;
      state.filerList[action.payload.pindex].types[action.payload.cindex].selected = 0;
      let selectedStatus = state.filerList.some(category => category.openType !== 9 && category.types.some(item => item.selected === 1));
      state.webinarPlanData.isSelectedFilter = selectedStatus;
  
      // state.selectedFilterList.splice(removeSelectIndex, 1);
    },
    setTextSearch: (state, action) => {
      let lastIndex = state.filerList.length - 1;
      state.filerList[lastIndex].types[0].name = action.payload;
      // let selectedText: CheckListType = {
      //   id: 1,
      //   name: action.payload,
      //   slug: "text-search",
      //   isChecked: false,
      //   menuType: 6,
      //   rank: 0,
      // };
      // let removeSelectIndex = state.selectedFilterList.findIndex(
      //   (r) => r.menuType === 6
      // );
      // if (removeSelectIndex > -1) {
      //   state.selectedFilterList.splice(removeSelectIndex, 1);
      // }
      // state.selectedFilterList.push(selectedText);
    },
    clearAllFilter: (state) => {
      state.filerList.map((filter) => {
        filter.isOpened = false;
        filter.types.map((checkList) => {
          checkList.isChecked = false;
          checkList.selected = filter.openType ===  9 ? 1 : 0;
          checkList.menuType = filter.openType;
          checkList.slug = checkList.slug
            ? checkList.slug
            : checkList.name.toLowerCase();
        });
      });
      state.webinarPlanData.isSelectedFilter = false
      state.webinarPlanData.selectedArray = []
    },
    clearPlanData: (state) => {
      state.webinarPlanData.recordedWebinarList = [];
    },
    setBookedIndex: (state, action) => {
      state.bookedIndex = action.payload.index;
      if(state.subscriptionData){
        if(action.payload.productType === 4)
        state.subscriptionData.noPurchasedWebinars += 1
        else
        state.subscriptionData.noPurchasedCourses +=1
      }
    },
    addToWebinarData : (state, action) => {
      let webinarListData : WebinarType = action.payload;
      let givenDate = new Date(webinarListData.webinarDate)
      let currentDate = new Date()
      if(givenDate > currentDate){
        state.webinarUpcomingData.unshift(webinarListData)
      }else{
         state.webinarPastData.webinars.unshift(webinarListData)
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpcomingData.pending, (state) => {
        state.loadingUpcomingWebinar = true;
      })

      .addCase(fetchUpcomingData.fulfilled, (state, action) => {
        if (action.payload) {
          let responseCode = action.payload.responseCode;
          if (responseCode === 200) {
            let webinarData: WebinarType[] = action.payload.response;
            webinarData &&
            webinarData.map((webinar) => {
              const wDate = new Date(webinar.webinarDate)
              webinar.webinarDate = new Date(wDate.getTime() - wDate.getTimezoneOffset() * 60000)
           
              return webinar;
            });
            state.webinarUpcomingData = webinarData;
            state.error = "";
          } else {
            state.error = "Something is wrong";
          }
        }
        state.loadingUpcomingWebinar = false;
      })
      .addCase(fetchUpcomingData.rejected, (state, action) => {
        state.loadingUpcomingWebinar = false;
        state.error = action.error.message;
      })

      .addCase(fetchPastWebinar.pending, (state) => {
        state.loadingPastWebinar =
          state.webinarPastData.pagination.pageNo === 1 ? true : false;
        state.webinarPastData.pagination.isLoading =
          state.webinarPastData.pagination.pageNo > 1 ? true : false;
      })
      .addCase(fetchPastWebinar.fulfilled, (state, action) => {
        if (action.payload) {
          let responseCode = action.payload.responseCode;
          if (responseCode === 200) {
            let webinarData: WebinarType[] =
              action.payload.response.webinarPastData;
            state.webinarPastData.pagination.isNext =
              action.payload.response.isNext;
            state.webinarPastData.pagination.total =
              action.payload.response.total;
            if(state.webinarPastData.pagination.pageNo === 1){
              state.webinarPastData.webinars = webinarData;
            } else {
              state.webinarPastData.webinars = [
                ...state.webinarPastData.webinars,
                ...webinarData,
              ];    
            }
            state.error = "";
          } else {
            state.error = "Something is wrong";
          }
        }
        state.loadingPastWebinar = false;
        state.webinarPastData.pagination.isLoading = false;
      })

      .addCase(fetchPastWebinar.rejected, (state, action) => {
        state.loadingPastWebinar = false;
        state.error = action.error.message;
      })

      .addCase(fetchSubscriptionPlan.pending, (state) => {
        state.loadingSubscription = true;
      })

      .addCase(fetchSubscriptionPlan.fulfilled, (state, action) => {
        if (action.payload) {
          let responseCode = action.payload.responseCode;
          if (responseCode === 200) {
            let subscriptionData: SubscriptionType =
              action.payload.response.data.currentSubscription.subscriptionInfo;
            state.subscriptionData = subscriptionData;

            state.error = "";
          } else {
            state.error = "Something is wrong";
          }
        }
        state.loadingSubscription = false;
      })

      .addCase(fetchSubscriptionPlan.rejected, (state, action) => {
        state.loadingSubscription = false;
        state.error = action.error.message;
      })

      .addCase(fetchFilterList.pending, (state) => {
        state.loadingFilterList = true;
      })

      .addCase(fetchFilterList.fulfilled, (state, action) => {
        if (action.payload) {
          let responseCode = action.payload.responseCode;
          if (responseCode === 200) {
            let filterData: FilterDataType[] = action.payload.response.data;
            filterData &&
              filterData.map((filter) => {
                filter.isOpened = false;
                filter.types.map((checkList) => {
                  checkList.isChecked = false;
                  checkList.selected = filter.openType===9?1 : 0;
                  checkList.menuType = filter.openType;
                  checkList.slug = checkList.slug
                    ? checkList.slug
                    : checkList.name.toLowerCase();
                });
              });
            // filterData.push({
            //   name: "Name",
            //   type: 6,
            //   isOpened: false,
            //   data: [
            //     {
            //       id: 1,
            //       name: "",
            //       slug: "text-search",
            //       isChecked: false,
            //       menuType: 6,
            //       rank: 0,
            //     },
            //   ],
            // });
            state.filerList = filterData;
            state.error = "";
          } else {
            state.error = "Something is wrong";
          }
        }
        state.loadingFilterList = false;
      })

      .addCase(fetchFilterList.rejected, (state, action) => {
        state.loadingFilterList = false;
        state.error = action.error.message;
      })

      .addCase(fetchWebinarList.pending, (state) => {
        state.loadingWebinarList =
          state.webinarPlanData.pagination.pageNo === 1 ? true : false;
        state.webinarPlanData.pagination.isLoading =
          state.webinarPlanData.pagination.pageNo > 1 ? true : false;
      })

      .addCase(fetchWebinarList.fulfilled, (state, action) => {
        if (action.payload) {
          let responseCode = action.payload.responseCode;
          if (responseCode === 200) {
            let upcomingWebinarList: WebinarListType[] = action.payload.response.data.upcomingResult;
            let recordedWebinarList : WebinarListType [] = action.payload.response.data.recordedResult
            state.webinarPlanData.pagination.isNext =
              action.payload.response.data.isNext;
            state.webinarPlanData.pagination.total =
              action.payload.response.total;
             state.webinarPlanData.upComingWebinarList = upcomingWebinarList
            state.webinarPlanData.recordedWebinarList = [
              ...state.webinarPlanData.recordedWebinarList,
              ...recordedWebinarList,
            ];
            state.error = "";
          } else {
            state.error = "Something wrong";
          }
        }
        state.loadingWebinarList = false;
        state.webinarPlanData.pagination.isLoading = false;
      })

      .addCase(fetchWebinarList.rejected, (state, action) => {
        state.loadingWebinarList = false;
        state.error = action.error.message;
      })

      .addCase(bookToWebinarList.pending, (state) => {
        state.isClickedBookNow = true;
      })

      .addCase(bookToWebinarList.fulfilled, (state, action) => {
        if (action.payload) {
          let responseCode = action.payload.responseCode;
          if (responseCode === 200) {
            state.bookNowMsg = action.payload.response.message;
            if (action.payload.response.success === true) {
              state.webinarPlanData.recordedWebinarList[state.bookedIndex].isAdded =
                1;
            } else {
              state.webinarPlanData.recordedWebinarList[state.bookedIndex].isAdded =
                0;
            }
            state.error = "";
          } else {
            state.error = "Something wrong";
          }
        }
        state.isClickedBookNow = false;
      })

      .addCase(bookToWebinarList.rejected, (state, action) => {
        state.isClickedBookNow = false;
        state.error = action.error.message;
      })
      
      .addCase(fetchWebinarDetails.pending, (state) => {
        state.loadingWebinarDetails = true;
      })
      .addCase(fetchWebinarDetails.fulfilled, (state, action) => {
        if (action.payload) {
          let responseCode = action.payload.responseCode;
          if (responseCode === 200 && action.payload.response) {
            state.webinarDetails = action.payload.response.userWebinar
            state.error = ""
        }else {
          state.error = "Something wrong";
        }
        state.loadingWebinarDetails = false;
      }
      })

      .addCase(fetchWebinarDetails.rejected, (state, action) => {
        state.loadingWebinarDetails = false;
        state.error = action.error.message;
      })
      ;
  },
});

export const {
  loadPagination,
  setPageNo,
  setWebinarError,
  filterToggle,
  checkedToogle,
  removeCheckList,
  setTextSearch,
  clearAllFilter,
  loadPaginationWebinarList,
  setWebinarListPageNo,
  clearPlanData,
  setBookedIndex,
  addToWebinarData,
  setSelectedArray
} = webinarSlice.actions;
